import React, { useEffect, useCallback } from "react";
import GoogleAnalytics from "react-ga";

const domain = process.env.REACT_APP_DOMAIN_NAME;

//domain是正式機, 才註冊GA, 應用程式序號:UA-180980211-1
if (domain.includes(".gov")) {
    GoogleAnalytics.initialize("UA-180980211-1");
}

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = (page) => {
        GoogleAnalytics.set({
            page,
            ...options,
        });
        GoogleAnalytics.pageview(page);
    };

    function HOC(props) {
        const page = props.location.pathname;
        const domain = process.env.REACT_APP_DOMAIN_NAME;

        const trackPageCallback = useCallback(() => {
            if (domain.includes(".gov")) {
                trackPage(page);
            }
        }, [domain, page]);

        useEffect(() => {
            trackPageCallback();
        }, [trackPageCallback]);

        return <WrappedComponent {...props} />;
    }
    return HOC;
};

export default withTracker;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function LanguageSetter() {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        // 在這裡可以根據路徑改變語言，這裡只是示範，您可能需要根據您的路由結構進行調整
        const isEnglish = path.includes("/en");
        const lang = isEnglish ? 'en' : 'zh-Hant-TW';

        document.documentElement.lang = lang;
    }, [location]);

    return null;
}

export default LanguageSetter;
import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Sticky from "react-stickynode";
import { Form } from "react-bootstrap";
import "../css/NavBar.scss";
import FontSizeChanger from "react-font-size-changer";
import { isWebpSupported } from "react-image-webp/dist/utils";

import CheckLoginAndRedirect from "../CheckLoginAndRedirect";
import greenLogo from "../../images1/green-logo.webp";
import greenLogoWebp from "../../images1/en_green_logo.webp";
import homeIcon from "../../images1/about/u84.png";

import aboutIcon from "../../images1/navBar/about.png";
import verifyIcon from "../../images1/navBar/verify.png";
import greenMarkIcon from "../../images1/navBar/greenMarkIcon_nav.png";
import greenProductIcon from "../../images1/navBar/greenProductIcon.png";

function EnglishNavBar() {
    //google站內搜尋
    useEffect(() => {
        const includeScript = () => {
            var cx = "7a502e0e8f34030f9";
            var gcse = document.createElement("script");
            gcse.type = "text/javascript";
            gcse.async = true;
            gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(gcse, s);
        };
        includeScript();
    }, []);

    const keyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            window.location.replace(`/search?q=${e.target.value}`);
            return false;
        }
    };

    const [dropDown, setDropDown] = useState(true);
    const [keyWord, setKeyWord] = useState("");
    const [gotoUrl, setGotoUrl] = useState("");

    return (
        <>
            <Sticky innerZ={100} enabled={true} top={0} bottomBoundary={0}>
                {gotoUrl && <CheckLoginAndRedirect key={gotoUrl} gotoUrl={gotoUrl} />}
                <nav className="nav-top">
                    <div className="top-dflex">
                        <h1 className="green-logo">
                            <Link as={Link} to="/en_Main">
                                <img src={isWebpSupported() ? greenLogoWebp : greenLogo} alt="Green Lifestyle LOGO" title="Green Lifestyle LOGO" />
                            </Link>
                        </h1>

                        <form className="form-inline-ori">
                            <ul className="nav-top-ul font">
                                <a className="skip-nav" href="#" title="網站搜尋" accessKey="s">
                                    :::
                                </a>
                                <div id="myDropdown" className="searchDropdown-content show">
                                    <Form.Control onKeyPress={(e) => keyPress(e)} type="text" title="站內搜尋" className="search-input" placeholder="type something..." onChange={(e) => setKeyWord(e.target.value === "" ? " " : e.target.value)} />
                                    <Link className="searchBtn-kn" to={`/search?q=${keyWord}`} id="searchBtn" title="searchBtn" aria-labelledby="searchBtn">
                                        <i className="fas fa-search" aria-labelledby="searchBtn"></i>
                                    </Link>
                                    <div className="pop-keyWord ">
                                        Buzzword：
                                        <Link to="/en_FlipTour" title=" Green Travel Buzzword Link">
                                            Green Travel、
                                        </Link>
                                        <Link to="/en_FlipFood" title="Eco-friendly Restaurant Buzzword Link">
                                            Eco-friendly Restaurant、
                                        </Link>
                                        <Link to="/en_FlipOffice" title="Green office Buzzword Link">
                                            Green office
                                        </Link>
                                    </div>
                                </div>
                                {/* </div> */}

                                <div className="font-changer d-inline-block" style={{ right: "0", width: "100px" }}>
                                    <FontSizeChanger
                                        targets={["#target"]}
                                        onChange={(element, newValue, oldValue) => {}}
                                        options={{
                                            stepSize: 1,
                                            range: 1,
                                        }}
                                        customButtons={{
                                            down: <span style={{ fontSize: "28px", cursor: "pointer" }}>A-</span>,
                                            up: <span style={{ fontSize: "28px", cursor: "pointer" }}>A+</span>,
                                            style: {
                                                backgroundColor: "transparent",
                                                color: "grey",
                                                border: "none",
                                                WebkitBoxSizing: "border-box",
                                                WebkitBorderRadius: "5px",
                                                width: "30px",
                                            },
                                            buttonsMargin: 10,
                                        }}
                                    />
                                </div>
                                <a className="skip-nav" href="#" title="site_map" accessKey="u">
                                    :::
                                </a>
                                <Link as={Link} to="/en_Main" title="">
                                    <li className="d-block nav-top-li border-left">
                                        <img src={homeIcon} alt="Home icon"></img>
                                        <p title="Home">HOME</p>
                                    </li>
                                </Link>
                                <Link as={Link} to="/en_SiteNav" title="">
                                    <li className="d-block nav-top-li">
                                        <i className="far fa-compass" aria-labelledby="siteNav"></i>
                                        <p title="Sitemap">SITEMAP</p>
                                    </li>
                                </Link>
                            </ul>
                            <div className="d-flex justify-content-center align-items-center nav-top-ul">
                                <Link to="/about">
                                    <button style={{ backgroundColor: "#6CB15E", color: "#fff", border: "none", borderRadius: "10px", padding: "8px 14px", margin: "0px 5px 0 20px", fontSize: "calc(10px + 1vw)", fontWeight: "600" }}>中</button>
                                </Link>
                            </div>
                        </form>
                    </div>
                </nav>

                <nav className="navbar navbar-expand-lg main-navbar ">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center offcanvas offcanvas-end" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropbtn" as={Link} to="/en_About#aboutFirst" role="button" aria-expanded="false">
                                    <img className="nav-img" src={aboutIcon} alt="" />
                                    About Green Lifestyle
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropbtn" as={Link} to="/en_About#whatWeDo" role="button" aria-expanded="false">
                                    <img className="nav-img" src={verifyIcon} alt="" />
                                    What We Do
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropbtn" as={Link} to="/en_Mark" role="button" aria-expanded="false">
                                    <img className="nav-img" src={greenMarkIcon} alt="" />
                                    Green Mark
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropbtn" as={Link} to="/en_Procurement" role="button" aria-expanded="false">
                                    <img className="nav-img" src={greenProductIcon} alt="" />
                                    Green Procurement
                                </Link>
                            </li>
                            <div id="" className="searchDropdown-content en-show">
                                <Form.Control onKeyPress={(e) => keyPress(e)} type="text" title="站內搜尋" className="search-input" placeholder="type something..." onChange={(e) => setKeyWord(e.target.value === "" ? " " : e.target.value)} />
                                <Link className="searchBtn-kn" to={`/search?q=${keyWord}`} id="searchBtn" title="searchBtn" aria-labelledby="searchBtn">
                                    <i className="fas fa-search" aria-labelledby="searchBtn"></i>
                                </Link>
                                <div className="pop-keyWord ">
                                    Buzzword：
                                    <Link to="/en_FlipTour" title=" Green Travel Buzzword Link">
                                        Green Travel、
                                    </Link>
                                    <Link to="/en_FlipFood" title="Eco-friendly Restaurant Buzzword Link">
                                        Eco-friendly Restaurant、
                                    </Link>
                                    <Link to="/en_FlipOffice" title="Green office Buzzword Link">
                                        Green office
                                    </Link>
                                </div>
                            </div>
                        </ul>
                    </div>
                </nav>
            </Sticky>
            <div className={dropDown ? "aside-backdrop" : "aside-backdrop show"} onClick={() => setDropDown(true)}></div>
        </>
    );
}

export default EnglishNavBar;

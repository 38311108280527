import React from "react";
import { Route, useHistory } from "react-router-dom";
import NavBar from "../Components/NavBar";
import EnglishNavBar from "../Components/EnglishVersion/EnglishNavBar";

const SideBtn = React.lazy(() => import("../Components/SideBtn"));
const NewFooter = React.lazy(() => import("../Components/NewFooter"));
const EnglishFooter = React.lazy(() => import("../Components/EnglishVersion/EnglishFooter"));

const NavRoute = ({ component: Component, showNavBar, showNewFooter, showNoFooter, showEnglishNavBar, showEnglishFooter, about, sideBtn, gamePage, restricted, SSL, domain, memberField, ...rest }) => {
    const history = useHistory();

    const renderFooter = () => {
        if (showNoFooter) {
            return null; // 不顯示 Footer
        } else if (showNewFooter) {
            return <NewFooter />;
        } else if (showEnglishFooter) {
            return <EnglishFooter />;
        }
        return <NewFooter />; // 預設情況下不顯示 Footer
    };

    return (
        <>
            {showNavBar && <NavBar />}
            {showEnglishNavBar && <EnglishNavBar />}
            <div id="target">
                <Route {...rest} render={(props) => <Component {...props} SSL={SSL} domain={domain} memberField={memberField} />} />
                <SideBtn sideBtn={sideBtn} gamePage={gamePage} about={about} history={history} />
                {renderFooter()}
            </div>
        </>
    );
};

export default NavRoute;

import React, { useState, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { isLogin, checkIdentityType } from "./Auth";
import NavBar from "../Components/NavBar";
import { AuthContext } from "../utils/Context";
import { Modal, Button } from "react-bootstrap";

const SideBtn = React.lazy(() => import("../Components/SideBtn"));
const InfoSideMenu = React.lazy(() => import("../Member/InfoSideMenu"));
const BreadCrumb = React.lazy(() => import("../Components/BreadCrumb"));

const PrivateRoute = ({ component: Component, showNavBar, SSL, domain, memberField, currentPage, migrated, accessRights, ...rest }) => {
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const handleGoBack = () => {
        setShowModal(false);
        history.goBack();
        
    };

    // 確保 accessRights 傳遞陣列
    const effectiveAccessRights = Array.isArray(accessRights) ? accessRights : [];

    const renderRoute = (props) => {
        if (!isLogin()) {
            return <Redirect to="/Login" />;
        } else {
            const userIdentityType = checkIdentityType("identityType");
            if (effectiveAccessRights.length === 0 || (userIdentityType && effectiveAccessRights.some((right) => userIdentityType.includes(right.toString())))) {
                return (
                    <>
                        <Component {...props} SSL={SSL} domain={domain} memberField={memberField} />;
                    </>
                );
            } else {
                setShowModal(true);
            }
        }
    };

    return (
        <>
            {showNavBar && <NavBar />}
            {migrated ? (
                <>
                    <BreadCrumb currentPage={currentPage} />
                    <div className="container member-info-wrapper row">
                        <InfoSideMenu />
                        <Route {...rest} render={renderRoute} />
                    </div>
                </>
            ) : (
                <Route {...rest} render={renderRoute} />
            )}

            <SideBtn history={history} />

            <Modal show={showModal} onHide={handleGoBack}>
                <Modal.Header>
                    <Modal.Title>您沒有權限進入此頁面</Modal.Title>
                </Modal.Header>
                <Modal.Body>請回到上一頁</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleGoBack}>
                        回上一頁
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PrivateRoute;
